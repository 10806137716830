import React from 'react';
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import PropTypes from 'prop-types';
import styles from './CreateDeepLinksPage.scss';
import Illustration from '../../../components/Illustration/Illustration';

function CreateDeepLinksPage({ submitData }) {
  setTimeout(() => submitData(), 5000);

  return (
    <>
      <div className={styles.successIllustration}>
        <Illustration illustrationSrc="/media/feedback/illustration-success.svg" />
      </div>
      <h1 className={styles.title}>All set</h1>
      <div className={styles.linkCreationDescription}>Link creation will start automatically in 5 seconds</div>
      <Button onClick={submitData} variant="filled" text="Start link creation" size="base" />
    </>
  );
}

CreateDeepLinksPage.propTypes = {
  submitData: PropTypes.func
};

export default CreateDeepLinksPage;
